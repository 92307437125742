import {React, useState} from 'react';
import { BigNumber } from 'ethers';
import styles from './Wallet.module.css';

import {db} from './firebase';
import {addDoc, collection, Timestamp} from 'firebase/firestore';

const Interactions = (props) => {

	const [transferHash, setTransferHash] = useState();


	const transferHandler = async (e) => {
		e.preventDefault();
		let transferAmount = e.target[0].value;
		let solanaAddress = e.target[1].value;
		let privacyPolicy = e.target[2].checked;
		let confirmAddress = e.target[3].checked;
		
		if(!transferAmount || !solanaAddress) return alert("Please fill all the fields");
		if(!privacyPolicy || !confirmAddress) return alert("Agree all checks!")
		
		const tokenDecimals = await props.contract.decimals();
		
		transferAmount = BigNumber.from(transferAmount).mul(Math.pow(10, tokenDecimals).toString());

		let txt;
		try{
			txt = await props.contract.transfer(props.recieverAddress, transferAmount);
		}catch(ex){
			return alert("Transaction canceled");
		}
		// wait txt to finish
		console.log(txt);
		setTransferHash("Transfer confirmation hash: " + txt.hash);
		
		try{
			await txt.wait();
			props.onSendAmount()
			console.log("Sending to firebase");
			await addDoc(collection(db, 'transaction'), {
				transferAmount: transferAmount.toString(),
				solanaAddress,
				from: props.walletAddress,
				hash: txt.hash,
				privacyPolicy,
				confirmAddress,
				created: Timestamp.now()
			});
		}catch (err){
			alert(err);
		}
	}

	return (
			<div className={styles.parent}>
				<form onSubmit={transferHandler} 
				className={styles.interactionsCard}>
					<h3> Transfer Tokens </h3>
					
					<div className={styles.interactionsCardItem}>
						<p className={styles.bold}> Send Amount </p>
						<input type='number' id='sendAmount' min='0' step='1'/>
					</div>

					<div className={styles.interactionsCardItem}>
						<p className={styles.bold}>Your solana wallet address </p>
						<input type='text' id='solanaAddress'/>
					</div>
					
					<div className={styles.interactionsCardItem}>
						<div className={styles.checkItem}>
							<input id="privacy-policy" name="privacy-policy" type="checkbox"/> 
							<label htmlFor="privacy-policy">Yes, I agree with @Pay's <a href="https://atpay.io/privacy-policy/">Privacy Policy</a> & token swap <a href="https://atpay.io/token-swap-terms-conditions/">Terms and Conditions</a>.</label>
						</div>
						<div className={styles.checkItem}>
							<input id="confirm-dest-addr" name="confirm-dest-addr" type="checkbox"/> 
							<label htmlFor="confirm-dest-addr">I confirm that this is the correct destination address.</label>
						</div>
					</div>

					<div className={styles.interactionsCardItem}>
						<button type='submit' className={`${styles.button6} ${styles.maxWidth}`}>Send</button>
					</div>
					<div>
						{transferHash}
					</div>
			</form>
			</div>
		)
	
}

export default Interactions;