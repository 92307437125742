import logo from './logo.svg';
import './App.css';
import Wallet from './Wallet';

function App() {
  return (
    <div className="App">
      <Wallet/>
    </div>
  );
}

export default App;
