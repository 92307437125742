import { React, useState, useEffect } from "react";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import styles from "./Wallet.module.css";
import simple_token_abi from "./Contracts/simple_token_abi.json";
import Interactions from "./Interactions";

import atpayLogo from './atpay-logo.svg';

const Wallet = () => {
  // deploy simple token contract and paste deployed contract address here. This value is local ganache chain
  let contractAddress = "0x69068e88C3be34bfAD1e12E8EbC604455D9A4e3b";

  const recieverAddress = '0xe1d4d91104aE33EFec8B031375a72ae7fF23e4b8';
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);

  const [tokenName, setTokenName] = useState("Token");
  const [balance, setBalance] = useState(null);
  const [transferHash, setTransferHash] = useState(null);

  const connectWalletConnectHandler = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      infuraId: "308ccfe81a624a3db919d8b49563f74c",
    });

    await walletConnectProvider.enable();

    window.ethereum = walletConnectProvider
    let tempProvider = new ethers.providers.Web3Provider(walletConnectProvider)
    accountChangedHandler((await tempProvider.listAccounts())[0]);
    setIsConnected(true);
    
  }

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          setIsConnected("Wallet Connected");
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    updateEthers();
  };

  const updateBalance = async () => {
    let balanceBigN = await contract.balanceOf(defaultAccount);

    let tokenDecimals = await contract.decimals();

    let tokenBalance = balanceBigN.div(Math.pow(10, tokenDecimals).toString());

    setBalance(toFixed(tokenBalance.toString()));
  };

  function toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  useEffect(()=>{
    if(window.ethereum) window.ethereum.on("accountsChanged", (accounts)=>accountChangedHandler(accounts[0]));
  }, []);

  if(window.ethereum) window.ethereum.on("chainChanged", chainChangedHandler);

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(
      contractAddress,
      simple_token_abi,
      tempSigner
    );
    setContract(tempContract);
  };

  useEffect(() => {
    if (contract != null) {
      updateBalance();
      updateTokenName();
    }
  }, [contract]);

  const updateTokenName = async () => {
    setTokenName(await contract.name());
  };

  return (
    <div>
      <img className={styles.atpayLogo} src={atpayLogo}/>
      <h2> {tokenName + " ERC-20 Wallet"} </h2>
      <div className={styles.buttonsContainer}>
      {!isConnected ?
          <>
            <button className={styles.button6} onClick={connectWalletHandler}>
              Connect MetaMask
            </button>

            <button className={styles.button6} onClick={connectWalletConnectHandler}>
              Connect Trust Wallet
            </button>
          </>:
          <div className={styles.accountId}>You are connected to atpay smart contract</div>
        }
        </div>


      <div className={styles.walletCard}>
        <div>
          <h3>
            {tokenName} Balance: {balance}
          </h3>
        </div>

        {errorMessage}
      </div>
      {contract && <Interactions contract={contract} walletAddress={defaultAccount} recieverAddress={recieverAddress} onSendAmount={updateBalance}/>}
    </div>
  );
};

export default Wallet;
